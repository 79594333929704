.react-calendar {
  background-color: #ffffff;
  border: 1px solid #007bbd;
  border-radius: 8px;
  font-family: Arial, sans-serif;
  color: #006c9b;
}

.react-calendar__navigation {
  background-color: #ffffff;
  border-bottom: 1px solid #007bbd;
  border-radius: 8px 8px 0 0;
  display: flex;
}

.react-calendar__navigation button {
  color: #006c9b;
  border: none;
  padding: 8px;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}

.react-calendar__navigation button:hover {
  background-color: #e5f6ff;
}

.react-calendar__month-view__weekdays {
  background-color: #007bbd;
  color: white;
  border-bottom: 1px solid #006c9b;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
}

.react-calendar__month-view__weekdays__weekday {
  color: white;
  font-weight: bold;
  padding: 4px;
}

.react-calendar__tile {
  padding: 4px;
  color: #006c9b;
  text-align: center;
  border-radius: 6px;
  background-color: white;
  transition: background-color 0.3s ease;
}

.react-calendar__tile:hover {
  background-color: #e5f6ff;
  cursor: pointer;
}

.react-calendar__tile--active {
  background-color: #007bbd !important;
  color: #ffffff;
  border-radius: 6px;
}

.react-calendar__tile--active:hover {
  background-color: #006c9b !important;
}

.react-calendar__tile--now {
  background-color: #e5f6ff;
  border: 1px solid #007bbd;
  border-radius: 6px;
}

.react-calendar__month-view__days {
  margin: 10px 0;
}

.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0.5;
}
