@import '@fontsource/nunito-sans';
@import 'react-datepicker/dist/react-datepicker.css';

:root {
  --color-primary: #007bbd;
  --color-primary-light: #e5f6ff;
  --color-primary-dark: #006c9b;

  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono',
    'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono',
    'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  padding-bottom: env(safe-area-inset-bottom);
}

html,
body,
#__next {
  max-width: 100vw;
  height: 100%;
  overflow-x: hidden;
  @apply bg-neutralBackground-secondary;
  @apply font-nunito;
}

.scroll-bar-hidden::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Handle */

::-webkit-scrollbar-track {
  background: #ebebeb;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/*----> BEGINNING === REACT DATE PICKER === BEGINNING <----  */
.react-datepicker__input-container input {
  font-size: 14px; /* Tamanho da fonte do valor */
  width: 100%;
  padding-left: 52px;
  padding-right: 16px;
  padding-bottom: 11.5px;
  padding-top: 11.5px;
  @apply rounded-[12px] border border-[#DADEE7] text-body-md text-neutralContent-primary focus:border-[#007BBD] focus:outline-none focus:ring-2 focus:ring-[#007BBD];
}

.react-datepicker__input-container input:disabled {
  @apply bg-gray-100;
}

.react-datepicker__input-container input::placeholder {
  font-size: 14px; /* Tamanho da fonte do placeholder */
  @apply text-body-md text-neutralContent-quartenary;
}

.react-datepicker-wrapper {
  @apply w-full;
}

.react-datepicker {
  @apply rounded-lg shadow-lg;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  @apply bg-[#007BBD] text-white;
}

.react-datepicker__day:hover {
  @apply bg-[#007BBD] text-white;
}

/* ----> END === REACT DATE PICKER === END <---- */

/* selectable-modal */
/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-scrollbar > div {
  padding-right: 16px;
}
.fc-theme-standard .fc-scrollgrid {
  border: none;
}

.fc-header-toolbar {
  border-top: 1px solid;
  border-right: 1px solid;
  border-left: 1px solid;
  margin-bottom: 0 !important;
  border-radius: 20px 20px 0 0;
  border-color: #dadee7;
}

.fc-header-toolbar .fc-toolbar-chunk div {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 20px;
}

.fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
  @apply mx-8 text-body-md text-neutralContent-primary first-letter:capitalize;
}

.fc-header-toolbar .fc-toolbar-chunk .fc-button {
  @apply border-0 bg-white p-2 text-neutralContent-primary transition-all hover:bg-primaryBrand-light;
}

.fc-day-today .fc-daygrid-day-number {
  @apply flex h-8 w-8 items-center justify-center rounded-full bg-primaryBrand-primary p-0 text-white;
}

.fc-header-toolbar .fc-toolbar-chunk .fc-button.fc-today-button {
  @apply mr-5 cursor-pointer border border-neutralBorder-default px-4 py-2;
}

/*  OCULTAR ICONE CALENDAR DO INPUT DATE */
input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* ---- END  ------- */

.react-calendar .selected-date {
  background-color: #cce4ff; /* Cor de fundo azul clara */
  color: #0044cc; /* Texto azul escuro */
  border-radius: 50%; /* Faz o círculo ao redor da data */
}

#superset-container iframe {
  width: 100%;
  height: 100%;
}

.short-link-trigger {
  display: none;
}
